<template>
  <div>
    <h2>System und Belastung</h2>
    <p>Zur Systemwahl stehen die Einfeldscheibe (i.R. bei Aussteifungssystemen mit aussenliegenden Wandscheiben) und die Kragscheibe (Aussteifung mit seitlichem Kern).
      Hinweis: Auch bei mehrfeldrigen Scheibensystemen genügt in der Regel der Nachweis für das ungüngstigste Feld als Einfeldscheibe oder Kragscheibe.
      Die Ausrichtung der Plattenelemente innerhalb der Scheibe ist festzulegen.
      Ferner sind die Scheibenabmessungen Länge x Breite ("L"x"H") und die Plattenstützweite "l" (nur bei Plattenlage in Scheibenlängsrichtung) einzugeben.
      Mit dem Auswahlfeld wird der Plattentyp und damit die Plattenstärke "h" festgelegt.</p>
  </div>
</template>

<script>
export default {
  name: "SystemsHelp"
};
</script>

<style lang="scss">
</style>